<template>
  <!-- <div class="index-content">
    <div class="scrollbar"> -->
  <div class="aboutBox">
    <div class="tabBox">
      <div
        :class="index == tabNum ? 'selectSty' : ''"
        @click="tabClick(index)"
        v-for="(item, index) in tabList"
        :key="index"
      >
        {{ item }}
      </div>
    </div>
    <div class="contentBox" v-if="tabNum == 0">
      <div class="contentLeft">
        &nbsp;&nbsp;&nbsp;&nbsp;广州松冬体育信息咨询有限公司成立于2011年，专注体育行业咨询策划和信息化服务领域，运用现代信息技术为体育行业赋能，推动体育消费服务升级，为各级体育行政部门、体育社团组织以及国际国内大型综合性体育赛事提供专业化服务。2020年，与中国联通集团全资子公司—中讯邮电咨询设计院有限公司联合成立中讯邮电咨询设计院有限公司智慧体育研究院（简称中讯智慧体育研究院），让科技与体育在多层次、全方位、宽领域融合发展，实现服务升级。
      </div>
      <div class="contentRight">
        <img src="../../assets/image/zhty.png" alt="" />
      </div>
    </div>
    <div class="twoBox" id="pcTwoBox" v-if="tabNum == 1">
      <div class="topBox">
        <div class="topOne">
          <img src="../../assets/image/zhty.png" alt="" />
          <div class="topOneText">
            广州松冬体育信息咨询有限公司于2011年成立，开启体育行业稳步发展征程。
          </div>
          <div class="topAfter">
            <div class="topAfterText">启程</div>
            <div class="topAfterYear">2011</div>
          </div>
        </div>
        <div class="topOne">
          <img src="../../assets/image/zhty.png" alt="" />
          <div class="topOneText">
            整合业务资源，确定发展方向，规划未来，建立体育大数据管理平台。
          </div>
          <div class="topAfter">
            <div class="topAfterText">整合</div>
            <div class="topAfterYear">2020</div>
          </div>
        </div>
      </div>
      <div class="topBox">
        <div class="topOne topTwo">
          <div class="topOneText">
            探寻体育行业发展方向，遵循体育发展规律，扎根并沉淀于体育大世界。
          </div>
          <img src="../../assets/image/zhty.png" alt="" />
          <div class="topAfter">
            <div class="topAfterText">探索</div>
            <div class="topAfterYear">2015</div>
          </div>
        </div>
        <div class="topOne topTwo">
          <div class="topOneText">
            以专业、敬业、宏业为初心展望未来，开拓客户市场，为客户提供专业可靠的服务。
          </div>
          <img src="../../assets/image/zhty.png" alt="" />
          <div class="topAfter">
            <div class="topAfterText">拓展</div>
            <div class="topAfterYear">未来</div>
          </div>
        </div>
      </div>
    </div>
    <div class="twoBox" id="phoneTwoBox" v-if="tabNum == 1">
      <div class="topBox">
        <div class="topOne">
          <img src="../../assets/image/zhty.png" alt="" />
          <div class="topOneText">
            广州松冬体育信息咨询有限公司于2011年成立，开启体育行业稳步发展征程。
          </div>
          <div class="topAfter">
            <div class="topAfterText">启程</div>
            <div class="topAfterYear">2011</div>
          </div>
        </div>
        <div class="topOne">
          <img src="../../assets/image/zhty.png" alt="" />
          <div class="topOneText">
            探寻体育行业发展方向，遵循体育发展规律，扎根并沉淀于体育大世界。
          </div>
          <div class="topAfter">
            <div class="topAfterText">探索</div>
            <div class="topAfterYear">2015</div>
          </div>
        </div>
        <div class="topOne">
          <img src="../../assets/image/zhty.png" alt="" />
          <div class="topOneText">
            整合业务资源，确定发展方向，规划未来，建立体育大数据管理平台。
          </div>
          <div class="topAfter">
            <div class="topAfterText">整合</div>
            <div class="topAfterYear">2020</div>
          </div>
        </div>
        <div class="topOne">
          <img src="../../assets/image/zhty.png" alt="" />
          <div class="topOneText">
            以专业、敬业、宏业为初心展望未来，开拓客户市场，为客户提供专业可靠的服务。
          </div>
          <div class="topAfter">
            <div class="topAfterText">拓展</div>
            <div class="topAfterYear">未来</div>
          </div>
        </div>
      </div>
    </div>
    <div class="fiveBox" v-if="tabNum == 2">
      <vue2-org-tree class="pcTree" name="test" :data="treeData" />
      <vue2-org-tree
        class="phoneTree"
        :horizontal="true"
        name="test"
        :data="treeData"
      />
    </div>
    <div class="threeBox" v-if="tabNum == 3">
      <div>
        <span>战略思想：</span
        >坚持厚积薄发的可持续发展战略思想，不断积累和丰富实战经验，壮大自身，服务客户。
      </div>
      <div>
        <span>服务精神：</span
        >始于热爱、止于至善，保持专业、敬业、宏业的核心价值观念，为客户提供高质服务。
      </div>
      <div>
        <span>服务定位：</span
        >专注于体育行业信息化和信息咨询服务，打造面向客户统一运营和面向产品专业运营的业务和运营支撑体系。
      </div>
      <div>
        <span>发展愿景：</span
        >依托现有业务经验，以广东省体育局为辐射点，向全国范围进行客户延伸。
      </div>
    </div>
    <div class="fourBox" v-if="tabNum == 4">
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;经过10年余的发展与沉淀，培养和积累了一批基础功底扎实、实战经验丰富、专业分工合理的体育行业服务人才队伍，团队核心人员具备北京奥运会、广州亚运会、深圳大运会、全国农民运动会、全国运动会、广东省运动会、杭州亚运会、汕头亚青会等大型赛事和广州市市运会、东莞市市运会及相关单项赛事的服务工作经历，有着丰富的体育赛事管理、计算机信息系统建设与服务的经验。
      </div>
      <div class="fourList">
        <div>
          <span>体育发展团队：</span
          >提供体育产业、体育项目相关的统筹规划和咨询服务，为大型综合性体育赛事提供专业化的技术支撑和咨询服务。
        </div>
        <div>
          <span>软件研发团队：</span
          >负责公司自有产品和客户定制化软件的框架构思设计、基础技术研究及业务功能实现和升级。
        </div>
      </div>
    </div>
  </div>
  <!-- </div>
  </div> -->
</template>

<script>
export default {
  name: "about-index",
  components: {},
  data() {
    return {
      tabList: ["公司简介", "发展历程", "组织架构", "企业文化", "团队介绍"],
      tabNum: 0,
      treeData: {
        label: "总经理",
        children: [
          {
            label: "行政部",
            children: [{ label: "部门经理" }, { label: "人事经理" }],
          },
          {
            label: "财务部",
            children: [
              { label: "部门经理" },
              { label: "财务" },
              { label: "出纳" },
            ],
          },
          {
            label: "软件开发部",
            children: [
              { label: "技术总监" },
              { label: "项目经理" },
              { label: "产品经理" },
              { label: "开发工程师" },
              { label: "UI设计师" },
              { label: "测试工程师" },
              { label: "实施工程师" },
            ],
          },
          {
            label: "体育发展部",
            children: [
              { label: "部门经理" },
              { label: "项目经理" },
              { label: "体育专员" },
            ],
          },
        ],
      },
    };
  },
  mounted() {
    // this.init();
  },
  methods: {
    tabClick(num) {
      this.tabNum = num;
    },
    init() {
      const self = this;
      let sign = self.sign;
      self.$store.commit("showLoading");

      getHome({ sign }).then((res) => {
        if (res.code == 200) {
          self.$store.commit("hideLoading");
        } else {
          self.$toast(res.msg);
        }
      });
    },
    onSearch() {},
  },
};
</script>
<style lang="scss">
@import "../../assets/scss/org-tree.scss";
</style>
<style lang="scss" scoped>
.aboutBox {
  width: 1200px;
  margin: 0 auto;
  min-height: 80%;
}
.tabBox {
  width: 100%;
  margin-top: 40px;
  div {
    display: inline-block;
    margin-right: 80px;
    cursor: pointer;
  }
  .selectSty {
    position: relative;
    color: #1e50ae;
    font-weight: bold;
    &::after {
      position: absolute;
      content: "";
      width: 46px;
      height: 2px;
      background-color: #1e50ae;
      bottom: -6px;
      left: 5%;
      border-radius: 4px;
    }
  }
}
.fiveBox {
  padding: 40px 0;
}
.twoBox {
  width: 100%;
  padding: 80px 0;
  display: flex;
  .topBox {
    flex: 1;
    &:first-child {
      border-right: 1px dashed #1e50ae;
    }
    .topOne.topTwo {
      margin-top: 200px;
      margin-bottom: 0;
      &::after {
        left: -6px;
      }
      .topAfter {
        left: -70px;
        right: auto;
        text-align: right;
      }
    }
    .topOne {
      position: relative;
      text-align: right;
      padding: 38px;
      margin-bottom: 200px;
      &::after {
        position: absolute;
        content: "";
        width: 12px;
        height: 12px;
        background-color: #1e50ae;
        border-radius: 50%;
        right: -6px;
        top: 50%;
        transform: translateY(-50%);
      }
      .topAfter {
        position: absolute;
        right: -70px;
        top: 50%;
        transform: translateY(-50%);
        text-align: left;
        .topAfterText {
          font-size: 18px;
          color: #1e50ae;
          margin-bottom: 10px;
          font-weight: bold;
        }
        .topAfterYear {
        }
      }
      img {
        width: 240px;
        height: 160px;
        border: 10px solid #ffffff;
        box-shadow: 0px 0px 20px 0px rgba(176, 209, 215, 0.3);
      }
      .topOneText {
        width: 240px;
        padding: 28px 16px 0;
        box-sizing: border-box;
        display: inline-block;
        text-align: left;
        line-height: 28px;
      }
    }
  }
}
.fourBox {
  padding: 40px 0;
  line-height: 34px;
  .fourList {
    padding: 10px 0;
    div {
      padding-left: 20px;
      span {
        position: relative;
        font-weight: bold;
        &::after {
          position: absolute;
          content: "";
          width: 6px;
          height: 6px;
          background-color: #1e50ae;
          left: -18px;
          top: 40%;
          transform: rotate(45deg);
        }
      }
    }
  }
}
.threeBox {
  padding: 40px 0;
  line-height: 34px;
  div {
    padding-left: 20px;
    span {
      position: relative;
      font-weight: bold;
      &::after {
        position: absolute;
        content: "";
        width: 6px;
        height: 6px;
        background-color: #1e50ae;
        left: -18px;
        top: 40%;
        transform: rotate(45deg);
      }
    }
  }
}
.contentBox {
  width: 100%;
  padding: 40px 0;
  box-sizing: border-box;
  display: flex;
  .contentLeft {
    flex: 1;
    padding: 10px 100px 0 0;
    box-sizing: border-box;
    line-height: 34px;
    span {
      font-weight: bold;
    }
  }
  .contentRight {
    width: 400px;
    img {
      width: 100%;
    }
  }
}
@media only screen and (min-width: 768px) {
  .fiveBox {
    .phoneTree {
      display: none;
    }
    .pcTree {
      display: block;
    }
  }
  #phoneTwoBox {
    display: none;
  }
  #pcTwoBox {
    display: flex;
  }
}
@media only screen and (max-width: 768px) {
  #phoneTwoBox {
    display: block;
  }
  #pcTwoBox {
    display: none;
  }
  .twoBox {
    padding: 40px 0;
    .topBox {
      width: 82%;
      .topOne {
        padding: 0;
        text-align: left;
        margin-bottom: 30px;
        .topOneText {
          padding: 10px 16px 0;
        }
        .topAfter {
          right: -58px;
        }
      }
    }
  }
  .fiveBox {
    .phoneTree {
      display: block;
      padding: 0;
    }
    .pcTree {
      display: none;
    }
  }
  .aboutBox {
    width: 92%;
    .tabBox {
      height: 30px;
      line-height: 30px;
      margin-top: 26px;
      white-space: nowrap;
      overflow-x: scroll;
      overflow-y: hidden;
      scrollbar-width: none;
      -ms-overflow-style: none;
      &::-webkit-scrollbar {
        display: none;
      }
      div {
        margin-right: 26px;
      }
      .selectSty {
        &::after {
          bottom: 0;
        }
      }
    }
    .contentBox {
      padding: 20px 0;
      display: block;
      .contentLeft {
        padding: 10px 0;
      }
      .contentRight {
        width: 100%;
      }
    }
  }
}
</style>
